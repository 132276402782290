.quote-proof-container {
  width: 100%;
  position: relative;
  height: 100vh;
  min-height: 861px;
  padding: 0;
  display: flex;
  max-height: 100vh;
  @media only screen and (max-width: 1440px) {
    height: 100vh;
    min-height: 400px;
  }
  .content {
    width: 100%;
    height: 100%;
  }

  .bg-container {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;
    flex: 1;
    height: 100%;
    width: 100%;
    display: flex;
  }

  .content-container {
    width: 100%;
    max-width: 1500px;
    margin: auto;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    &.content-on-right {
      justify-content: flex-end;
    }

    @media only screen and (min-width: 768px) and (max-width: 1550px) {
      max-width: 80%;
    }

    @media (max-width: 768px) {
      align-items: flex-end;
    }
  }

  .text-content-wrapper {
    width: 540px;
    flex-shrink: 0;
    border-radius: 15px;
    opacity: 0.95;
    background: #f2f1ed;
    z-index: 99;
    margin-top: 130px;

    @media only screen and (min-width: 768px) and (max-width: 1024px) {
      max-width: 500px;
      margin-top: 0;
    }
    @media (max-width: 768px) {
      margin-top: 0;
      justify-content: center;
      max-width: 320px;
      margin: auto;
      margin-bottom: 34px;
    }
  }

  .text-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 62px 65px;

    @media only screen and (min-width: 768px) and (max-width: 1024px) {
      padding: 66px 58px 69px;
    }
    @media (max-width: 768px) {
      padding: 45px 24px 34px;
    }
  }

  .richText-wrapper {
    color: #595a59;
    text-align: center;
    font-family: Amiri;
    font-size: 47.8px;
    font-style: normal;
    font-weight: 400;
    line-height: 96.2%;
    letter-spacing: -0.5px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: unset;
    margin-bottom: unset;
    padding-bottom: 2px;
    p {
      line-height: 96.2%;
      padding-bottom: 2px;
      margin: 0;
    }

    @media only screen and (min-width: 768px) and (max-width: 1024px) {
      font-size: 44.4px;
    }

    @media (max-width: 768px) {
      font-size: 34px;
      line-height: 105.7%;
      letter-spacing: -0.7px;
    }
  }

  .richText-wrapper-paragraph {
    margin-top: 10px;
    color: #595a59;
    text-align: center;
    font-family: 'Open Sans';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 170%;
    max-width: 336px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;

    @media only screen and (min-width: 768px) and (max-width: 1024px) {
      margin-top: 15.8px;
      font-size: 14.8px;
      line-height: 169.7%;
    }
    @media (max-width: 768px) {
      font-size: 14px;
      line-height: 169.7%;
    }
  }

  .ctas-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 27px;
    margin-top: 55px;

    > * {
      min-width: 170px;
      @media only screen and (min-width: 768px) and (max-width: 1024px) {
        min-width: 158px;
      }
      @media (max-width: 768px) {
        margin-top: 10px;
        min-width: 120px;
      }
    }

    @media only screen and (min-width: 768px) and (max-width: 1024px) {
      margin-top: 51px;
    }
    @media (max-width: 768px) {
      margin-top: 20px;
      gap: 15px;
    }
  }

  .richTextQuoteProof-paragraph {
    font-family: 'Open Sans';
    font-size: 16px;
    font-style: normal;
    line-height: 169.781%;
    color: #595a59;
    text-align: center;
    font-weight: 400;
  }

  .richTextQuoteProof-header {
    font-family: 'Amiri';
    font-size: 47.852px;
    font-style: normal;
    font-weight: 400;
    line-height: 96.281%; /* 46.073px */
    letter-spacing: -0.479px;
    color: #595a59;
    text-align: center;
    font-weight: 400;
  }

  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    .richTextQuoteProof-paragraph {
      font-family: 'Open Sans';
      font-size: 14.859px;
      font-style: normal;
      font-weight: 400;
      line-height: 169.781%;
    }

    .richTextQuoteProof-header {
      font-family: 'Amiri';
      font-size: 44.44px;
      font-style: normal;
      font-weight: 400;
      line-height: 96.281%;
      letter-spacing: -0.444px;
    }
  }

  @media (max-width: 768px) {
    .richTextQuoteProof-paragraph {
      font-family: 'Open Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 169.781%;
    }

    .richTextQuoteProof-header {
      font-family: 'Amiri';
      font-size: 34px;
      font-style: normal;
      font-weight: 400;
      line-height: 105.781%;
      letter-spacing: -0.68px;
    }
  }
}
