@mixin media-hero-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

.split-hero {
  h2,
  p {
    color: $hero-slate-black;
  }

  a:focus,
  button:focus {
    box-shadow: none !important;
  }

  .hero-dusty-beige {
    background-color: $hero-dusty-beige;
  }

  .hero-pale-olive {
    background-color: $hero-pale-olive;
  }

  .hero-slate-blue {
    h2,
    p {
      color: $white;
    }
    background-color: $hero-slate-blue;
  }

  .hero-light-gray {
    background-color: $hero-light-gray;
  }

  .hero-white {
    background-color: $hero-white;
  }

  .hero-dusty-beige,
  .hero-pale-olive {
    .search-button-container {
      background-color: $hero-slate-blue;
    }
    .search-button-label {
      color: $white;
    }
  }

  .hero-slate-blue,
  .hero-light-gray,
  .hero-white {
    .search-button-container {
      background-color: $hero-slate-black;
    }
    .search-button-label {
      color: $white;
    }
  }

  .hero-dusty-beige,
  .hero-pale-olive,
  .hero-light-gray,
  .hero-white {
    .split-hero-ctas-container-residence {
      a:first-child,
      button:first-child,
      div:first-child button {
        background-color: $hero-slate-blue !important;
        color: $white !important;
        border: 1px solid $hero-slate-blue !important;
      }

      a:first-child:hover,
      button:first-child:hover,
      div:first-child button:hover {
        background: $hero-slate-blue !important;
        color: $white !important;
      }

      a:not(:first-child),
      button:not(:first-child),
      div:not(:first-child) button {
        background-color: transparent !important;
        color: $hero-slate-black !important;
        border: 1px solid $hero-slate-black !important;
      }

      a:not(:first-child):hover,
      button:not(:first-child):hover,
      div:not(:first-child) button:hover {
        background: transparent !important;
        color: $hero-slate-black !important;
      }
    }

    .split-hero-ctas-container-corporate {
      .call2action {
        color: $hero-slate-black !important;
      }
    }
  }

  .hero-slate-blue {
    .split-hero-eyebrow-text {
      color: $white;
    }

    .split-hero-ctas-container-residence {
      a:first-child,
      button:first-child,
      div:first-child button {
        background-color: $hero-slate-black !important;
        color: $white !important;
        border: 1px solid $hero-slate-black !important;
      }

      a:not(:first-child),
      button:not(:first-child),
      div:not(:first-child) button {
        background-color: transparent !important;
        color: $white !important;
        border: 1px solid $white !important;
      }
    }

    .split-hero-ctas-container-corporate {
      .call2action {
        color: $white !important;
      }
    }
  }

  &-corporate-height {
    height: fit-content;
    @include media-hero-breakpoint-up(lg) {
      height: 670px;
      min-height: 670px;
    }
  }
  &-residence-height {
    height: fit-content;
    @include media-hero-breakpoint-up(lg) {
      height: 580px;
      min-height: 580px;
    }
  }

  &-container:has(.hero-white) {
    #herobanner-searchbox {
      outline: 1px solid $black !important;
    }
  }

  &-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-direction: column-reverse;
    width: 100%;
    overflow: hidden;
    @include media-hero-breakpoint-up(lg) {
      flex-direction: row;
    }
  }

  &-reversed {
    @include media-hero-breakpoint-up(lg) {
      flex-direction: row-reverse;
    }
  }

  &-content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: fit-content;
    padding-top: 24px;
    padding-bottom: 32px;
    max-width: 100%;
    @include media-hero-breakpoint-up(sm) {
      padding: 80px;
    }
    &-wrapper {
      width: 100%;
      max-width: 570px;
    }
  }

  &-image {
    height: 288px;
    @include media-hero-breakpoint-up(sm) {
      height: 389px;
    }
  }

  &-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &-content,
  &-image {
    width: 100%;
    @include media-hero-breakpoint-up(lg) {
      width: 50%;
      height: 100%;
    }
  }

  &-eyebrow-text {
    font-size: 14px;
    font-weight: 400;
    font-family: 'Open Sans', sans-serif;
    line-height: 19px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    margin-top: 0px;
    margin-bottom: 12px;
    @include media-hero-breakpoint-up(sm) {
      margin-bottom: 24px;
      font-size: 20px;
    }
  }

  &-title {
    font-size: 32px;
    line-height: 45px;
    margin-bottom: 0;
    @include media-hero-breakpoint-up(sm) {
      font-size: 58px;
      line-height: 74px;
    }
  }

  &-description {
    font-size: 20px;
    line-height: 22.4px;
    margin: 16px 0px 0px 0px;
    font-family: 'Open Sans', sans-serif;
    @include media-hero-breakpoint-up(sm) {
      font-size: 20px;
      line-height: 25.6px;
    }
  }

  &-title {
    font-family: Amiri, serif;
    font-weight: 400;
  }
  &-description {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
  }
  &-ctas-container-corporate {
    display: flex;
    flex-direction: column;
    width: 100% !important;
    position: unset;
    transform: none;
    padding: 0;
    margin-top: 12px;

    // search bar styles for corporate start
    input,
    #herobanner-searchbox,
    #herobannerResidenceSearchBox {
      height: 53px;
      max-height: 53px !important;
    }

    #herobanner-searchbox {
      order: -9999 !important;
      margin-left: 0 !important;
      margin-right: 0 !important;
      background: $white !important;
      input {
        padding-left: 48px !important;
      }
      input::placeholder {
        font-style: normal !important;
        color: #767676 !important;
      }
    }

    .search-button-label {
      display: block !important;
      font-size: 16px;
      font-weight: 400;
      font-family: 'Open Sans', sans-serif;
      line-height: 19px;
      text-transform: uppercase;
    }

    .search-button-label + svg {
      display: none;
    }

    .split-hero-search-icon {
      position: absolute;
      top: 14px;
      left: 14px;
      display: block !important;
      fill: #767676;
    }

    .search-button-container {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 112px !important;
    }

    .search-button-container button {
      margin: 0 !important;
    }

    .search-button-container:has(.search-button-label) {
      text-align: center;
    }
    // search bar styles for corporate end

    // see all residences cta styles for corporate start
    .call2action {
      display: flex;
      width: 100%;
      height: 24px;
      align-items: center;
      justify-content: flex-end;
      border: 0px !important;
      background: transparent;
      text-transform: none !important;
      text-decoration: underline;
      text-underline-offset: 2px;
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      padding: 0;
      margin-top: 32px;
      @include media-hero-breakpoint-up(sm) {
        margin-top: 24px;
      }
      @include media-hero-breakpoint-up(lg) {
        margin-top: 16px;
      }

      svg {
        display: none;
      }

      .split-hero-find-residences-cta-icon {
        display: block !important;
        width: 24px !important;
        max-width: 24px !important;
        height: 24px !important;
        margin-left: 12px !important;
        margin-right: 0px !important;
        margin-bottom: -2px !important;
      }
    }
    // see all residences cta styles for corporate end
  }

  &-ctas-container-residence {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    row-gap: 12px !important;
    width: 100% !important;
    position: unset !important;
    transform: none !important;
    padding: 0;
    margin-top: 24px;
    @include media-hero-breakpoint-up(sm) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      column-gap: 20px !important;
    }

    & > *,
    & a,
    & button {
      width: 100% !important;
      height: 53px !important;
      margin-top: 0px !important;
    }

    & a,
    & button {
      margin-top: 0px !important;
    }

    & a {
      display: flex !important;
      align-items: center;
      justify-content: center;
    }

    & svg {
      display: none;
    }
  }
}
